import type {SportTypePreview, Country} from "@spoferan/spoferan-ts-core";
import sportTypeEmoji from "../config/emoji/sport.json";
import countryEmoji from "../config/emoji/country.json";

export function getSportTypeEmoji(sportType: SportTypePreview) {
    let emoji = '';

    if (sportType && sportType._key) {
        emoji = sportTypeEmoji.hasOwnProperty(sportType._key) ? sportTypeEmoji[sportType._key] : '';
    }

    return emoji;
}

export function getCountryEmoji(countryCode: string) {
    const code = countryCode.toUpperCase();
    return countryEmoji.hasOwnProperty(code) ? countryEmoji[code] : '';
}

